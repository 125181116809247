<template>
  <div class="container-top">
    <div class="pc container padding-top-60">
      <div style="text-align:center">
        <img src="/static/icon/u_check-circle.svg" class="svg-primary"
             style="width:54px;height:54px;margin-bottom: 32px">
        <div class="h4 main">결제 / 다운로드가 완료되었습니다.</div>
        <div class="body2-web sub2" style="margin:12px 0 32px 0">구매한 템플릿은 마이페이지에서 확인 가능합니다.</div>
      </div>
      <div class="box-title">결제 정보</div>
      <div class="flex" style="flex-wrap: wrap">
        <div class="col-3 item-col subtitle5 main">총 결제금액</div>
        <div class="col-9 item-col body2 sub"><span class="h5 main price">{{ order.total_price|currencyNum }}</span>원</div>
        <div class="col-3 item-col subtitle5 main">결제 수단</div>
        <div class="col-9 item-col body2 sub">{{ order.credit }}</div>
      </div>
      <div style="margin:80px 0 120px 0" class="flex-center">
        <button class="button is-gray" style="width:242px" @click="clickMypage">다운로드 내역</button>
      </div>
    </div>
    <div class="mobile">
      <div class="mo-container text-center">
        <img src="/static/icon/u_check-circle.svg" class="svg-primary"
             style="width:40px;height:40px;margin-bottom: 12px">
        <div class="h7 main">결제 / 다운로드가 완료되었습니다.</div>
        <div class="body4 sub2 margin-top-12">구매한 템플릿은 마이페이지에서 확인 가능합니다.</div>
        <div class="flex-center margin-top-24">
          <button class="button is-gray" style="width:145px;height:44px" @click="clickMypage">다운로드 내역</button>
        </div>
      </div>
      <div class="mo-container">
        <div class="h8 main" style="margin-bottom:4px">결제정보</div>
        <div class="flex" style="flex-wrap: wrap">
          <div class="col-3 item-col body4-medium main">총 결제금액</div>
          <div class="col-9 item-col body4 sub"><span class="body4-bold main price">{{ order.total_price|currencyNum }}</span>원</div>
          <div class="col-3 item-col body4-medium main">결제 수단</div>
          <div class="col-9 item-col body4 sub">{{ order.credit }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TemplateAccountDone",
    created() {

    },
    data() {
      return {
      }
    },
    computed: {
      order() {
        return this.$store.getters.basket;
      }
    },
    beforeDestroy() {
      this.$store.commit('clearBasket');
    },
    methods: {
      clickMypage() {
        this.$router.replace('/mypage/my_templates');
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .box-info
    background-color $gray4
    padding 40px
    border-radius 8px
    display flex
    flex-wrap wrap
    text-align left
    width 564px
    margin 32px auto

  .box-title
    padding 16px 0
    border-bottom 1px solid $sub5
    font-weight 700
    font-size 18px
    line-height 24px
    margin-top 70px

  .item-col
    padding-top 24px

  .mo-container
    padding 24px 16px
    border-bottom 8px solid $gray2
  .mo-container:first-child
    padding 32px 16px
  .mo-container:last-child
    border-bottom 0

  .mobile
    .item-col
      padding 0
      margin-top 12px
</style>
